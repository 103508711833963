var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('PageHeader',{attrs:{"screenName":_vm.pageTitle,"linkItems":_vm.linkItems}}),_c('ConfirmModal',{attrs:{"itemIdentifier":"ponto de atendimento","modalShow":_vm.modal.showModal,"typeModal":_vm.modal.typeModal},on:{"confirmed":_vm.cancelRegister,"canceled":_vm.closeModalConfirm}}),_c('SucessoModal',{attrs:{"modalShow":_vm.modalSuccess.showModal,"typeModal":_vm.modalSuccess.typeModal,"caseModal":_vm.modalSuccess.caseModal},on:{"confirmed":_vm.redirectToList}}),_c('ErrorModal',{attrs:{"modalShow":_vm.modalError.showModal,"typeModal":_vm.modalError.typeModal,"caseModal":_vm.modalError.caseModal},on:{"confirmed":_vm.redirectToList}}),_c('main',{staticClass:"card p-2"},[_c('section',{staticClass:"form"},[_c('validation-observer',{ref:"pontoAtendimentoRules"},[_c('b-form',{class:{'opacity-50': _vm.loadingData}},[_c('b-row',[_c('b-col',{staticClass:"pr-lg-0",attrs:{"lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome do Ponto de Atendimento*","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.loadingData,"state":errors.length > 0 || _vm.form.errors.name ? false:null,"id":"name","autocomplete":"off","placeholder":"Ex: Farmácia SESI Trindade"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.name)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.name)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{staticClass:"pr-lg-0",attrs:{"lg":"4","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo*","label-for":"type","state":errors.length > 0 || _vm.form.errors.type ? false:null}},[_c('v-select',{attrs:{"disabled":_vm.loadingData,"id":"type","reduce":(function (option) { return option.id_tipo_ponto_atendimento; }),"options":_vm.servicePointType,"label":"descricao","placeholder":"Selecione o tipo"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.type)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.type)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Codigo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Código*","label-for":"code"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"disabled":_vm.loadingData,"state":errors.length > 0 || _vm.form.errors.code ? false:null,"id":"code","autocomplete":"off","placeholder":"0000"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.code)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.code)+" ")]):_vm._e(),_c('span',{staticClass:"infoInput"},[_vm._v("Informe o código referente a este ponto de atendimento.")])],1)]}}])})],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"pr-lg-0",attrs:{"lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"CEP*","label-for":"cep"}},[_c('validation-provider',{attrs:{"name":"cep","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],attrs:{"disabled":_vm.loadingData,"state":errors.length > 0 || _vm.form.errors.cep ? false:null,"id":"cep","autocomplete":"off","placeholder":"00000-000"},on:{"update":_vm.searchCep},model:{value:(_vm.form.cep),callback:function ($$v) {_vm.$set(_vm.form, "cep", $$v)},expression:"form.cep"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.cep)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.cep)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{staticClass:"pr-lg-0",attrs:{"lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Rua/Avenida*","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.loadingData,"state":errors.length > 0 || _vm.form.errors.address ? false:null,"id":"address","autocomplete":"off","placeholder":"Ex: Admar Gonzaga"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.address)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.address)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Número*","label-for":"number"}},[_c('validation-provider',{attrs:{"name":"number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(('X'.repeat(15))),expression:"('X'.repeat(15))"}],attrs:{"disabled":_vm.loadingData,"state":errors.length > 0 || _vm.form.errors.number ? false:null,"id":"number","autocomplete":"off","placeholder":"Ex: 54"},model:{value:(_vm.form.number),callback:function ($$v) {_vm.$set(_vm.form, "number", $$v)},expression:"form.number"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.number)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.number)+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"pr-lg-0",attrs:{"lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Complemento","label-for":"complement"}},[_c('b-form-input',{attrs:{"disabled":_vm.loadingData,"state":_vm.form.errors.complement ? false:null,"id":"complement","autocomplete":"off","placeholder":"EX: Bloco B, 2ª andar, sala 201"},model:{value:(_vm.form.complement),callback:function ($$v) {_vm.$set(_vm.form, "complement", $$v)},expression:"form.complement"}}),(_vm.form.errors.complement)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.complement)+" ")]):_vm._e()],1)],1),_c('b-col',{staticClass:"pr-lg-0",attrs:{"lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Bairro*","label-for":"neighborhood"}},[_c('validation-provider',{attrs:{"name":"neighborhood","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.loadingData,"state":errors.length > 0 || _vm.form.errors.neighborhood ? false:null,"id":"neighborhood","autocomplete":"off","placeholder":"Ex: Sede/SC"},model:{value:(_vm.form.neighborhood),callback:function ($$v) {_vm.$set(_vm.form, "neighborhood", $$v)},expression:"form.neighborhood"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.neighborhood)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.neighborhood)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cidade*","label-for":"city","state":errors.length > 0 || _vm.form.errors.city ? false:null}},[_c('v-select',{attrs:{"disabled":_vm.loadingData,"id":"city","reduce":(function (option) { return option.id_cidade; }),"options":_vm.cities,"label":"nome","placeholder":"Selecione uma cidade"},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.city)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.city)+" ")]):_vm._e()],1)]}}])})],1)],1),_c('UnidadeField',{attrs:{"error":_vm.form.errors.operatingUnit,"selectDisabled":_vm.loadingData,"preSelected":this.form.operatingUnit,"edit":""},on:{"selected":_vm.fillUnidade}})],1)],1)],1),_c('section',{staticClass:"buttons mt-3"},[_c('b-button',{staticClass:"mr-2 mb-1",attrs:{"id":"save-ponto-atendimento","variant":"custom-blue","disabled":_vm.saving || _vm.loadingData},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Salvar ponto de atendimento")]),(_vm.saving)?_c('span',{staticClass:"spinner-border spinner-border-sm ml-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1),_c('b-button',{staticClass:"mb-1",attrs:{"id":"cancel-register-ponto-atendimento","disabled":_vm.saving,"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.openModalConfirm.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Cancelar")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }