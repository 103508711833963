<template>
    <div class="content-wrapper">
        <PageHeader :screenName="pageTitle" :linkItems="linkItems"/>

        <ConfirmModal
            itemIdentifier="ponto de atendimento"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="cancelRegister"
            @canceled="closeModalConfirm"
        />

        <SucessoModal
            :modalShow="modalSuccess.showModal"
            :typeModal="modalSuccess.typeModal"
            :caseModal="modalSuccess.caseModal"
            @confirmed="redirectToList"
        />

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirectToList"
        />

        <main class="card p-2">
            <section class="form">
                <validation-observer ref="pontoAtendimentoRules">
                    <b-form :class="{'opacity-50': loadingData}">
                        <b-row>
                            <b-col lg="4" sm="12" class="pr-lg-0">
                                <b-form-group label="Nome do Ponto de Atendimento*" label-for="name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="name"
                                        rules="required"
                                    >
                                        <b-form-input
                                            :disabled="loadingData"
                                            :state="errors.length > 0 || form.errors.name ? false:null"
                                            id="name"
                                            autocomplete="off"
                                            v-model="form.name"
                                            placeholder="Ex: Farmácia SESI Trindade"
                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.name" class="text-danger">
                                            {{ form.errors.name }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" sm="12" class="pr-lg-0">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Tipo"
                                    rules="required"
                                >
                                    <b-form-group
                                        label="Tipo*"
                                        label-for="type"
                                        :state="errors.length > 0 || form.errors.type ? false:null">
                                        <v-select
                                            :disabled="loadingData"
                                            id="type"
                                            :reduce="(option => option.id_tipo_ponto_atendimento)"
                                            v-model="form.type"
                                            :options="servicePointType"
                                            label="descricao"
                                            placeholder="Selecione o tipo"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger">
                                             Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.type" class="text-danger">
                                            {{ form.errors.type }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4" sm="12">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Codigo"
                                    rules="required"
                                >
                                <b-form-group label="Código*" label-for="code">
                                    <b-form-input
                                        :disabled="loadingData"
                                        :state="errors.length > 0 || form.errors.code ? false:null"
                                        id="code"
                                        v-mask="'#####'"
                                        autocomplete="off"
                                        v-model="form.code"
                                        placeholder="0000"
                                    />
                                    <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="form.errors.code" class="text-danger">
                                        {{ form.errors.code }}
                                    </small>
                                    <span class="infoInput">Informe o código referente a este ponto de atendimento.</span>
                                </b-form-group>
                            </validation-provider>
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col lg="4" sm="12" class="pr-lg-0">
                                <b-form-group label="CEP*" label-for="cep">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="cep"
                                        rules="required"
                                    >
                                        <b-form-input
                                            :disabled="loadingData"
                                            :state="errors.length > 0 || form.errors.cep ? false:null"
                                            id="cep"
                                            v-mask="'#####-###'"
                                            autocomplete="off"
                                            @update="searchCep"
                                            v-model="form.cep"
                                            placeholder="00000-000"
                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.cep" class="text-danger">
                                            {{ form.errors.cep }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" sm="12" class="pr-lg-0">
                                <b-form-group label="Rua/Avenida*" label-for="address">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="address"
                                        rules="required"
                                    >
                                        <b-form-input
                                            :disabled="loadingData"
                                            :state="errors.length > 0 || form.errors.address ? false:null"
                                            id="address"
                                            autocomplete="off"
                                            v-model="form.address"
                                            placeholder="Ex: Admar Gonzaga"
                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.address" class="text-danger">
                                            {{ form.errors.address }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" sm="12">
                                <b-form-group label="Número*" label-for="number">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="number"
                                        rules="required"
                                    >
                                        <b-form-input
                                            :disabled="loadingData"
                                            :state="errors.length > 0 || form.errors.number ? false:null"
                                            id="number"
                                            autocomplete="off"
                                            v-mask="('X'.repeat(15))"
                                            v-model="form.number"
                                            placeholder="Ex: 54"
                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.number" class="text-danger">
                                            {{ form.errors.number }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col lg="4" sm="12" class="pr-lg-0">
                                <b-form-group label="Complemento" label-for="complement">
                                    <b-form-input
                                        :disabled="loadingData"
                                        :state="form.errors.complement ? false:null"
                                        id="complement"
                                        autocomplete="off"
                                        v-model="form.complement"
                                        placeholder="EX: Bloco B, 2ª andar, sala 201"
                                    />
                                    <small v-if="form.errors.complement" class="text-danger">
                                        {{ form.errors.complement }}
                                    </small>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" sm="12" class="pr-lg-0">
                                <b-form-group label="Bairro*" label-for="neighborhood">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="neighborhood"
                                        rules="required"
                                    >
                                        <b-form-input
                                            :disabled="loadingData"
                                            :state="errors.length > 0 || form.errors.neighborhood ? false:null"
                                            id="neighborhood"
                                            autocomplete="off"
                                            v-model="form.neighborhood"
                                            placeholder="Ex: Sede/SC"
                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.neighborhood" class="text-danger">
                                            {{ form.errors.neighborhood }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" sm="12">
                                <validation-provider #default="{ errors }" name="city" rules="required">
                                    <b-form-group
                                        label="Cidade*"
                                        label-for="city"
                                        :state="errors.length > 0 || form.errors.city ? false:null">
                                        <v-select
                                            :disabled="loadingData"
                                            id="city"
                                            :reduce="(option => option.id_cidade)"
                                            v-model="form.city"
                                            :options="cities"
                                            label="nome"
                                            placeholder="Selecione uma cidade"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.city" class="text-danger">
                                            {{ form.errors.city }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                        <UnidadeField
                            :error="form.errors.operatingUnit"
                            @selected="fillUnidade"
                            :selectDisabled="loadingData"
                            :preSelected="this.form.operatingUnit"
                            edit
                        />
                    </b-form>
                </validation-observer>
            </section>
            <section class="buttons mt-3">
                <b-button id="save-ponto-atendimento"
                    class="mr-2 mb-1"
                    variant="custom-blue"
                    @click.prevent="validationForm"
                    :disabled="saving || loadingData"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar ponto de atendimento</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-register-ponto-atendimento"
                    class="mb-1"
                    :disabled="saving"
                    variant="outline-danger"
                    @click.prevent="openModalConfirm"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import UnidadeField from '@/views/components/custom/field-select-unidade-ponto-atendimento/UnidadeField.vue';
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue';
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import vSelect from 'vue-select';
import handleErrorsService from './Services/HandleErrors';
import {getUserData} from "@/auth/utils";
import { getIdPontoAtendimento, getDescricaoPontoAtendimento } from '@core/utils/store/getStore';
import { setIdPontoAtendimento, setDescricaoPontoAtendimento } from "@core/utils/store/setStore";

export default {
    title: 'Editar ponto de atendimento',

    components: {
        PageHeader, BRow, BCol, BForm, BFormGroup, BButton,
        BFormInput, ValidationProvider, ValidationObserver, vSelect,
        UnidadeField, ConfirmModal, SucessoModal, ErrorModal
    },

    data() {
        return {
            userData:getUserData(),
            linkItems: [
                {
                    name: 'Pontos de Atendimento',
                    routeName: 'ponto-atendimento-list'
                },
                {
                    name: 'Editar',
                    active: true
                }
            ],
            pageTitle: 'Editar Ponto de Atendimento',
            required,
            saving: false,
            loadingData: true,
            notSearchCep: true,
            form: {
                name: null,
                type: null,
                code: null,
                cep: null,
                address: null,
                number: null,
                complement: null,
                neighborhood: null,
                city: null,
                operatingUnit: null,
                region: null,
                errors: {
                    name: null,
                    type: null,
                    code: null,
                    cep: null,
                    address: null,
                    number: null,
                    complement: null,
                    neighborhood: null,
                    city: null,
                    operatingUnit: null,
                }
            },
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSuccess: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'edicao'
            },
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            servicePointType: [],
            cities: []
        }
    },

    mounted() {
        const hasModalError = this.$helpers.verifyIfCanOpenModalError(getIdPontoAtendimento(), this.$route.params.id_ponto_atendimento );
        const hasIdEntityRouteParam = this.$helpers.verifyIfHasIdEntityRouteParam(getIdPontoAtendimento(), this.$route.params.id_ponto_atendimento );
        
        if(hasModalError)
            return this.openModalError();
        
        if(hasIdEntityRouteParam) {
            setIdPontoAtendimento(this.$route.params.id_ponto_atendimento); 
            setDescricaoPontoAtendimento(this.$route.params.descricao);
        }

        this.linkItems[1].name = 'Editar ' + getDescricaoPontoAtendimento();
        this.pageTitle = 'Editar ' + getDescricaoPontoAtendimento();

        this.loadServicePointType();
        this.loadCities();
        this.loadPontoAtendimento();
    },

    methods: {
        validationForm() {
            this.$refs.pontoAtendimentoRules.validate().then(success => {
                if (success) {
                    this.saving = true;
                    this.editPontoAtendimento();
                }
            })
        },

        editPontoAtendimento() {
            const parameters = {
                descricao: this.form.name,
                id_tipo_ponto_atendimento: this.form.type,
                codigo: this.form.code,
                cep: this.form.cep.replace('-', ''),
                endereco: this.form.address,
                numero: this.form.number,
                complemento: this.form.complement,
                bairro: this.form.neighborhood,
                id_cidade: this.form.city,
                id_unidade: this.form.operatingUnit
            };

            this.$http.put(this.$api.pontoAtendimentoParameter(getIdPontoAtendimento()), parameters).then(() => {
                this.saving = false;
                this.openModalSuccess();
            }).catch(({ response: { data } }) => {
                handleErrorsService.handleErrosPontoAtendimento(data, this.form.errors);

                this.saving = false;
            });
        },

        loadPontoAtendimento() {
            this.$http.get(this.$api.pontoAtendimentoParameter(getIdPontoAtendimento())).then(({ data }) => {
                this.form.name = data.descricao;
                this.form.type = data.id_tipo_ponto_atendimento;
                this.form.code = data.codigo;
                this.form.cep = data.cep;
                this.form.address = data.endereco;
                this.form.number = data.numero;
                this.form.complement = data.complemento;
                this.form.neighborhood = data.bairro;
                this.form.city = data.cidade.id_cidade;
                this.form.operatingUnit = data.unidade[0].id_unidade;

                this.loadingData = false;
            }).catch(() => {
                this.redirectToList();
            });
        },

        loadCities() {
            this.$http.get(this.$api.getCidadesDoEstado(this.userData.departamento.uf)).then(({ data }) => {
                this.cities = data.cidades;
            })
        },

        loadServicePointType() {
            this.$http.get(this.$api.getServicePointTypes()).then(({ data }) => {
                this.servicePointType = data;
            });
        },

        closeModalConfirm() {
            this.modal.showModal = false;
        },

        openModalConfirm() {
            this.modal.showModal = true;
        },

        openModalSuccess() {
            this.modalSuccess.showModal = true;
        },

        openModalError() {
            this.modalError.showModal = true;
        },

        cancelRegister() {
            this.$router.push({ name: 'ponto-atendimento-list' });
        },

        redirectToList() {
            this.$router.push({ name: 'ponto-atendimento-list' });
        },

        searchCep(cepMasked) {
            if(this.notSearchCep) {
                this.notSearchCep = false;
                return;
            }

            this.form.errors.cep = null;

            const cep = cepMasked.replace('-', '');

            if(cep.length == 8) {
                this.$cep.searchCep(
                    cep, this.form,
                    this.form.errors, this.cities
                );
            }
        },

        fillUnidade(event) {
            this.form.operatingUnit = event;
        }
    }
}
</script>

<style>
.infoInput {
    font-size: 0.9rem;
    color: #B9B9C3;
    display: block;
}
</style>
